<template>
  <v-card class="ma-2">
    <v-card-title class="pink lighten-1 white--text">
      Задачи на сегодня
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-2">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-simple-table fixed-header :height="contentHeight + 0 + 'px'">
          <template v-slot:default>
            <thead class="main-table-header">
              <tr>
                <th class="text-left">Дата и время</th>
                <th class="text-left">Заголовок</th>
                <th class="text-left">Кем создана</th>
                <th class="text-left">Кому назначена</th>
                <th class="text-left">Тип</th>
                <th class="text-left">Приоритет</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(el, i) in data"
                :key="i"
                @click="
                  showTaskDialog = true;
                  idEdit = el.id;
                "
                style="cursor: pointer"
              >
                <td class="text-left">
                  <a-view-table-datetime :value="el.date_event" />
                </td>
                <td class="text-left">
                  <a-view-table-taskname :value="el.name" :values="el" />
                </td>
                <td class="text-left">{{ el.creator_name }}</td>
                <td class="text-left">{{ el.user_name }}</td>
                <td class="text-left">{{ el.type }}</td>
                <td class="text-left">
                  <a-view-table-chip
                    :value="el.is_important"
                    :model="{
                      options:
                        $store.getters['config/get'].models.tasks
                          .optionsIsImportant,
                    }"
                  />
                </td>
              </tr>
            </tbody> </template
        ></v-simple-table>
      </div>
      <div v-else>Задач на сегодня нет</div>
      <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    taskDialog: () => import("../dialogs/taskDialog.vue"),
  },
  data() {
    return {
      showTaskDialog: false,
      idEdit: 0,
      loading: false,
      data: [],
    };
  },
  created() {
    this.fitchData();
  },
  methods: {
    async fitchData() {
      this.loading = true;
      this.data = (
        await this.$axios.get("/kazan/tasks", {
          params: {
            sort: { key: "date_event", order: "ASC" },
            filters: {
              // user_id: this.$root.profile.id,
              is_show_main: 1,
              // status: [1, 2],
            },
          },
        })
      ).data.data;
      this.loading = false;
    },
  },
};
</script>